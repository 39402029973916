@import '../../../scss/mixin.scss';

.page-not-found-container {
  background: var(--light-orange);
  min-height: 100vh;
  margin: -57px auto 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  h1 {
    font-family: var(--cadiz);
    margin: 0px;
    font-size: 100px;
    font-weight: 600;
    line-height: 100px;
  }
  h2 {
    font-size: 24px;
    line-height: 33px;
    font-family: var(--cadiz);
  }
  button {
    margin: 0 70px;
  }
}
