@import './_mixin.scss';

.app-btn {
  background-color: var(--accent-primary) !important;
  border-radius: 6px !important;
  padding: 16px 32px !important;
  text-transform: none !important;
  font-size: 16px !important;
  font-family: var(--cadiz) !important;
  font-weight: 900 !important;
  box-shadow: none !important;
  height: 56px;
  &:active {
    background-color: var(--accent-primary-dark) !important;
  }
  &.app-btn-outlined {
    background-color: transparent !important;
    border: 1.5px solid var(--accent-primary) !important;
    color: var(--accent-primary);
    &:active {
      background-color: var(--neutral-secondary-dark) !important;
    }
  }
  &.Mui-disabled {
    background-color: var(--black-02) !important;
    color: var(--neutral-secondary) !important;
  }
  @include screen(mobile) {
    padding: 16px 10px !important;
  }
}
a,
.link {
  color: var(--accent-primary) !important;
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

// Disable ripple effect across app
.MuiTouchRipple-root {
  display: none !important;
}

.content-body {
  min-height: calc(100vh - 58px);
  padding-bottom: 97px;
  &.tertiary-background {
    background-color: var(--neutral-tertiary);
  }
}

.content-wrapper {
  padding: 0 24px;
}

.line-through {
  text-decoration: line-through;
}

// colour text
.green-text {
  color: var(--positive-primary-dark);
}

// Footer
.footer-continue {
  padding: 16px 24px;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  max-width: 550px;
  height: 89px;
  &.dual-buttons {
    display: flex;
    justify-content: space-between;
    * {
      flex-basis: calc(50% - 8px);
    }
  }
  &.bordered {
    border-top: 1px solid rgba(53, 56, 61, 0.08);
  }
  &.neutral-background {
    background-color: var(--neutral-tertiary);
  }
}
.login-signup-custom-box {
  background: var(--light-orange);
  padding-top: 56px;
  padding-bottom: 12px;
  position: relative;
  text-align: center;
  &::after {
    position: absolute;
    width: 550px;
    height: 28px;
    content: '';
    bottom: -26px;
    right: 0;
    background-image: url(../assets/pinkafter.png);
    background-repeat: no-repeat;
    background-position: right bottom;
  }
  @include screen(mobile) {
    padding-top: 24px;
  }
  h2 {
    margin-top: 5px;
  }
}

.divider {
  margin: 24px 0;
  border: 1px solid var(--neutral-secondary-darker);
  border-block-start-style: none;
}
.MuiFormControl-root {
  label {
    font-family: var(--cadiz);
    color: var(--neutral-primary-light);
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    top: 3px;
    &[data-shrink='true'] {
      text-transform: uppercase;
      color: var(--neutral-primary-light);
      z-index: 1;
    }
  }

  .MuiInput-root::before {
    border-bottom: 1px solid var(--neutral-secondary-darker);
  }
  .MuiInput-root::after {
    border-bottom-color: var(--accent-primary);
  }
}

.loading-lottie {
  width: 280px;
}

.MuiFilledInput-root {
  overflow: 'hidden';
  background-color: transparent !important;
  border: 1px solid var(--neutral-secondary-darker) !important;
  border-radius: 8px !important;
  color: var(--neutral-primary) !important;
  font-family: var(--cadiz);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  height: 64px;
  &:hover {
    background-color: 'transparent' !important;
    border-bottom: none;
    border: 1px solid var(--accent-primary) !important;
  }
  &:focus {
    background-color: 'transparent' !important;
    border-bottom: none;
    border: 1px solid var(--accent-primary) !important;
  }
  &::after {
    border-bottom: none !important;
  }
  &::before {
    border-bottom: none !important;
  }
}

.MuiOutlinedInput-root {
  overflow: 'hidden';
  background-color: transparent !important;
  border: 1px solid var(--neutral-secondary-darker) !important;
  border-radius: 8px !important;
  font-family: var(--cadiz);
  font-size: 16px;
  line-height: 24px;
  color: var(--neutral-primary);
  height: 64px;
  &:hover {
    background-color: 'transparent' !important;
    border-bottom: none !important;
    border: 1px solid var(--accent-primary) !important;
  }
  &:focus {
    background-color: 'transparent' !important;
    border-bottom: none !important;
    border: 1px solid var(--accent-primary) !important;
  }
  &::after {
    border-bottom: none !important;
  }
  &::before {
    border-bottom: none !important;
  }
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.Mui-error {
  border-color: var(--negative-primary) !important;
  &:hover,
  &:focus {
    border-color: var(--negative-primary) !important;
  }
}
.MuiOutlinedInput-input {
  padding: 25px 0px 8px 12px !important;
}
