.share-appointment-card-container {
  background-color: var(--honey-50);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 8px;
  overflow: hidden;
  .content {
    padding: 16px;
    padding-right: 8px;
    h4 {
      margin: 0px;
      font-size: 16px;
      font-family: var(--cadiz);
      font-weight: bold;
    }
    p {
      margin-top: 4px;
      margin-bottom: 16px;
      font-size: 16px;
      font-family: var(--cadiz);
    }
    .MuiButton-root {
      border-radius: 6px;
      padding: 8px 12px;
      text-transform: none;
      font-size: 16px;
      font-family: var(--sf-pro);
      font-weight: 900;
      border: 1.5px solid var(--accent-primary) !important;
      color: var(--accent-primary);
      &.app-btn-outlined {
        background-color: transparent;
        border: 1.5px solid var(--accent-primary) !important;
        color: var(--accent-primary);
      }
    }
  }
  .image {
    img {
      width: 112px !important;
      height: 100%;
      object-fit: cover;
      mask-image: url(../../../assets/images/shareTreatmentCardMask.svg);
      mask-size: cover;
    }
  }
}
