@import './mixin';

html {
  //Root em declaration
  //1rem = 12px
  font-size: 16px;
  line-height: 24px;

  @include screen(sm-desktop) {
    font-size: 16px;
    line-height: 24px;
  }

  @include screen(mobile) {
    font-size: 14px;
  }
}

// Headings
h1 {
  font-family: var(--tobias);
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 56px;
  @include screen(mobile) {
    font-size: 40px;
    line-height: 48px;
  }
}

h2 {
  font-family: var(--tobias);
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 48px;
  @include screen(mobile) {
    font-size: 32px;
    line-height: 40px;
  }
}

h3 {
  font-family: var(--tobias);
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  @include screen(mobile) {
    font-size: 24px;
    line-height: 32px;
  }
}

h4 {
  font-family: var(--tobias);
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  @include screen(mobile) {
    font-size: 20px;
    line-height: 32px;
  }
}

// Body
.body-1 {
  font-family: var(--cadiz) !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;

  .bold {
    font-weight: bold;
  }
}

.body-2 {
  font-family: var(--cadiz);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  .bold {
    font-weight: bold;
  }
}

.body-3 {
  font-family: var(--cadiz);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  .bold {
    font-weight: bold;
  }
}

// Mono
.mono-1 {
  font-family: var(--cadiz);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.mono-2 {
  font-family: var(--cadiz);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.label {
  font-family: var(--cadiz);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  &.bold {
    font-weight: bold;
  }
}
