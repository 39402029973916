@import '../../../scss/mixin';

.get-app-container {
  background-color: var(--sky-20);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 8px;
    background-color: var(--accent-primary);
    width: fit-content;
    text-transform: none;
  }
  .MuiButton-root {
    font-family: var(--cadiz);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--neutral-secondary);
    border-radius: 8px;
    &:active {
      background-color: var(--accent-primary) !important;
    }
    &:hover {
      background-color: var(--accent-primary) !important;
    }
    .MuiButton-startIcon {
      margin: 0px;
    }
  }
  .text-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 24px 0px 24px 24px;
    h3 {
      margin: 0;
    }
    .body1 {
      font-family: var(--cadiz);
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--neutral-primary);
      padding-top: 8px;
      padding-bottom: 24px;
      @include screen(mobile) {
        padding: 0px;
      }
    }
    .title {
      font-family: var(--tobias);
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 40px;
      color: var(--neutral-primary);
      @include screen(mobile) {
        font-size: 24px;
        line-height: 32px;
      }
    }
    @include screen(mobile) {
      justify-content: space-between;
    }
  }
  svg {
    border-radius: 8px;
  }
  .image {
    border-radius: 8px;
  }
}

.get-some-face-time {
  background-color: var(--neutral-tertiary);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  .btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 8px;
    background-color: var(--accent-primary);
    width: fit-content;
    text-transform: none;
  }
  .MuiButton-root {
    font-family: var(--cadiz);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--neutral-secondary);
    border-radius: 8px;
    &:active {
      background-color: var(--accent-primary) !important;
    }
    &:hover {
      background-color: var(--accent-primary) !important;
    }
  }
  .text-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 32px 0px 32px 24px;
    h3 {
      margin: 0;
    }
    .body1 {
      font-family: var(--cadiz);
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--neutral-primary);
      padding-top: 8px;
      padding-bottom: 24px;
      @include screen(mobile) {
        padding-bottom: 16px;
      }
    }
    .title {
      font-family: var(--tobias);
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 40px;
      color: var(--neutral-primary);
      @include screen(mobile) {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
  .v-icon {
    display: block;
    height: 100%;
    float: right;
  }
  .h-icon {
    display: none;
  }
  svg {
    border-radius: 8px;
  }
  @include screen(mobile) {
    flex-direction: column-reverse;
    .v-icon {
      display: none;
    }
    .h-icon {
      display: block;
    }
    .title {
      font-family: var(--tobias);
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      color: var(--neutral-primary);
    }
    .body1 {
      font-family: var(--cadiz);
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--neutral-primary);
    }
    .text-container {
      padding: 24px;
    }
  }
}
