@import '../../../scss/mixin';
.MuiDialog-paper {
  max-width: 320px;
  margin: 0px;
  padding: 24px;
  box-shadow:
    6px 8px 12px rgba(0, 0, 0, 0.04),
    1px 1px 4px rgba(0, 0, 0, 0.08),
    4px 6px 8px rgba(0, 0, 0, 0.06) !important;
  border-radius: 8px !important;
  .dialog-title {
    margin: 0;
    text-align: center;
    color: var(--neutral-primary);
  }
  .dialog-content {
    text-align: center;
    color: var(--neutral-primary-light);
    margin-top: 8px;
  }
  .btn-section {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    gap: 8px;
    .red-btn {
      background-color: var(--negative-primary-dark) !important;
      border-radius: 6px !important;
      padding: 16px 32px !important;
      text-transform: none !important;
      font-weight: 600 !important;
      box-shadow: none !important;
      height: 56px;
      color: var(--neutral-secondary);
      &:active {
        background-color: var(--accent-primary-dark) !important;
      }
      &.red-btn-outlined {
        background-color: transparent !important;
        border: 1.5px solid var(--accent-primary) !important;
        color: var(--accent-primary);
        &:active {
          background-color: var(--neutral-secondary-dark) !important;
        }
      }
      &.Mui-disabled {
        background-color: var(--black-02) !important;
        color: var(--neutral-secondary) !important;
      }
      @include screen(mobile) {
        padding: 16px 10px !important;
      }
    }
  }
}
