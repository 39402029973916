body {
  margin: 0;
}
.main {
  background-color: rgba(0, 0, 0, 0.7);
  position: relative;
  z-index: 9;
  width: 100%;
  min-height: 100vh;
  top: 0;
  .main-box {
    width: 550px;
    background: var(--white);
    min-height: 100vh;
    margin: 0 auto;
    position: relative;
    padding-top: 57px;
    overflow: hidden;
    @media (max-width: 550px) {
      width: 100%;
    }
  }
}
