:root {
  --neutral-primary: #35383d;
  --neutral-primary-08: #35383d3f;
  --neutral-secondary: #ffffff;
  --neutral-tertiary: #f7eee8;
  --accent-primary: #105368;
  --positive-primary: #bac4b2;
  --positive-primary-dark: #4e8046;
  --negative-primary: #a11f1f;
  --negative-primary-dark: #871a1a;
  --negative-primary-darker: #a14141;
  --neutral-primary-light: #676a6d;
  --neutral-primary-lighter: #9a9b9e;
  --neutral-secondary-dark: #f3f2f2;
  --neutral-secondary-darker: #e1dede;
  --neutral-tertiary-dark: #ebd5c6;
  --neutral-tertiary-darker: #e3c5af;
  --accent-primary-dark: #0c3f4f;
  --white: #ffffff;
  --black: #000000;
  --black-02: #0000001f;
  --light-silver: #d8d8d8;
  --iron: #677178;
  --tin: #ededed;
  --oxygen: #fcefdc;
  --yellow-mist: #f5c47a;
  --light-orange: #f5e4da;
  --blue: #345b74;
  --dark-blue: #333f48;
  --red: #ff1a79;
  --light-brown: #d8ac8c;
  --loader-backdrop: rgba(0, 0, 0, 0.3);
  --peony-50: #e9cccc;
  --sky-50: #b8d5de;
  --sky-20: #e3eef2;
  --sage-50: #d1d8cb;
  --honey-50: #f8d8a6;
  --honey-75: #f5c47a;
  --honey-80: #f5c378;
  --navy-blue: #003da6;
  --ocean-20: #cfdde1;
  --ocean-50: #87a9b3;
  --ocean-75: #4c7e8e;
  --summer-green: #2dae99;
  --cadiz: 'Cadiz', sans-serif;
  --tobias: 'Tobias', sans-serif;
  --sf-pro: 'SF Pro', sans-serif;
}
