/* Regular Tobias*/
@font-face {
  font-family: 'Tobias';
  src:
    url('Tobias-Regular.otf') format('opentype'),
    url('Tobias-Regular.ttf') format('truetype'),
    url('Tobias-Regular.woff') format('woff'),
    url('Tobias-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

/* Regular Tobias Italic*/
@font-face {
  font-family: 'Tobias';
  src:
    url('Tobias-RegularItalic.otf') format('opentype'),
    url('Tobias-RegularItalic.ttf') format('truetype'),
    url('Tobias-RegularItalic.woff') format('woff'),
    url('Tobias-RegularItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}

/* Regular Cadiz*/
@font-face {
  font-family: 'Cadiz';
  src:
    url('CadizWeb-Regular.woff') format('woff'),
    url('CadizWeb-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

/* Regular Cadiz Italic*/
@font-face {
  font-family: 'Cadiz';
  src:
    url('CadizWeb-RegularItalic.woff') format('woff'),
    url('CadizWeb-RegularItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}

/* Cadiz Semi Bold*/
@font-face {
  font-family: 'Cadiz';
  src:
    url('CadizWeb-SemiBold.woff') format('woff'),
    url('CadizWeb-SemiBold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

/* Regular SF pro */
@font-face {
  font-family: 'SF Pro';
  src: url('SF-Pro-Text-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

/* bold Regular SF pro */
@font-face {
  font-family: 'SF Pro';
  src: url('SF-Pro-Text-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}
