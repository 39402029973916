// screen detection mixin
@mixin screen($size) {
  $desktop: 1800px; // xxxl
  $md-desktop: 1599px; // xxl
  $sm-desktop: 1199px; // xl
  $tablet: 1023px; // lg
  $landscape: 767px; // md
  $mobile: 400px; // sm

  @if $size == desktop {
    @media only screen and (max-width: #{$desktop}) {
      @content;
    }
  } @else if $size == md-desktop {
    @media only screen and (max-width: #{$md-desktop}) {
      @content;
    }
  } @else if $size == sm-desktop {
    @media only screen and (max-width: #{$sm-desktop}) {
      @content;
    }
  } @else if $size == tablet {
    @media only screen and (max-width: #{$tablet}) {
      @content;
    }
  } @else if $size == landscape {
    @media only screen and (max-width: #{$landscape}) {
      @content;
    }
  } @else if $size == mobile {
    @media only screen and (max-width: #{$mobile}) {
      @content;
    }
  } @else {
    @media only screen and (#{$size}) {
      @content;
    }
  }
}

//center absolutely using flexbox
@mixin flexCentered {
  display: flex;
  justify-content: center;
  align-items: center;
}

// animation mixin
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @-ms-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}
