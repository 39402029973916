body {
  margin: 0;
  font-family: var(--cadiz);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--neutral-primary);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Required for proper button behavior */
button {
  all: unset;
}
