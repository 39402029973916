@import '../../../scss/mixin';
.esth-list-container {
  padding-bottom: 10px;
  .selected-location-area {
    margin: 16px 24px;
    display: flex;
    align-items: center;

    .sub-head-1 {
      margin-right: 10px;

      h4 {
        color: var(--neutral-primary-light);
        font-family: var(--cadiz);
        margin: 0;
      }
    }
    .sub-head-2 {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      display: flex;
      align-items: center;
      color: var(--accent-primary);
      .map-icon {
        width: 24px;
        height: 24px;
        @include screen(mobile) {
          width: 16px;
          height: 16px;
        }
      }
      .border-box {
        margin: 0px 4px;
        border-bottom: 1.5px solid var(--accent-primary);
        padding-bottom: 2px;
        word-break: break-all;

        a {
          text-decoration: none;
        }
      }
    }

    @media only screen and (max-width: 570px) {
      .sub-head-1 {
        font-size: 20px;
      }
      .sub-head-2 {
        font-size: 16px;
        .map-icon {
          width: 18px;
        }
      }
    }
  }

  .estheticianlist-footer {
    background-color: var(--neutral-secondary-dark);
    margin: 24px;
    text-align: center;
    padding: 16px 0;
    font-family: var(--cadiz);
    font-size: 16px;
    border-radius: 8px;
    p {
      margin: 0;
      color: var(--black);
      margin-bottom: 8px;
    }
    a,
    .link {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
    .estheticianlist-footerlink {
      font-weight: 600;
      color: var(--accent-primary);
      text-decoration: none;
    }
  }

  .no-results {
    text-align: center;
    margin-top: 72px;
    h3 {
      margin: 16px 24px 8px;
    }
    p {
      margin: 0 8px;
    }
  }
  .no-results-month {
    text-align: center;
    margin-top: 72px;
    h3 {
      margin: 0 24px;
      margin-top: 16px;
      font-weight: 400;
      font-size: 32px;
      line-height: 40px;
      white-space: nowrap;
      @include screen(mobile) {
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
      }
    }
    p {
      margin: 0;
      font-family: var(--cadiz);
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
    .parent-wrapper {
      margin-top: 8px;
      p {
        color: var(--neutral-primary-light);
      }
    }
  }
  .select-location {
    padding: 0px 24px;
    color: var(--neutral-primary-light);
    font-weight: 400;
    font-family: var(--cadiz);
    font-size: 20px;
    line-height: 28px;
    margin-top: 16px;
    margin-bottom: 24px;
    display: block;
    svg {
      color: var(--accent-primary);
    }
    .selected-location {
      display: flex;
      align-items: center;
    }
    .sub-head {
      color: var(--accent-primary) !important;
      cursor: pointer;
      border-bottom: 1.5px solid var(--accent-primary);
      font-family: var(--cadiz);
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      margin: 0px 4px;
      padding-bottom: 1px;
      text-decoration: none;
      vertical-align: top;
    }
    .laser-sub-head {
      cursor: default;
    }
    .map-icon {
      margin-left: 8px;
    }
  }
}
.offer-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--sky-50);
  color: var(--neutral-primary);
  font-family: var(--cadiz);
  font-size: 16px;
  line-height: 24px;
  padding: 16px;
  margin: 8px;
  border-radius: 8px;
  z-index: 9999;
  position: fixed;
  max-width: 534px;
  gap: 16px;
  animation-name: 'test';
  bottom: 0;
  .calender-icon {
    height: 16px;
    width: 16px;
  }
  .message {
    display: inline;
    align-items: center;
  }
}

.cssanimation {
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.fadeInBottom {
  animation-name: fadeInBottom;
}
@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}
