@import '../../../scss/mixin';

.facial-section {
  padding-bottom: 80px;
}

.facial-header {
  background: var(--light-orange);
  text-align: center;
  position: inherit;

  h2 {
    margin-top: 0;
    margin-bottom: 0px;
    padding-top: 56px;
    @include screen(mobile) {
      padding-top: 24px;
    }
  }
  p {
    margin-top: 8px;
    padding: 0 100px 12px 70px;
  }

  &::after {
    position: absolute;
    width: 550px;
    height: 28px;
    content: '';
    bottom: -26px;
    right: 0;
    background-image: url(../../../../src/assets/pinkafter.png);
    background-repeat: no-repeat;
    background-position: right bottom;
  }
}

.facial-content {
  margin-top: 48px;
}
.facial-card {
  border: 1.5px solid #e1dede;
  margin: 12px 24px;
  padding: 24px;
  border-radius: 8px;
  cursor: pointer;
  .facial-details {
    display: flex;
    justify-content: space-between;
    gap: 14px;
    h3 {
      text-align: left;
      font-weight: 400;
      font-size: 24px;
      margin: 0px 0px 4px;
    }
    .facial-description {
      text-align: left;
      margin: 0;
      width: auto;
      max-width: 400px;
      font-size: 12px;
      line-height: 16px;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
    .facial-price {
      text-align: center;
      min-width: 67px;
    }
    .member-price {
      font-size: 20px;
      line-height: 28px;
      color: var(--positive-primary-dark);
    }
    .non-member {
      background: var(--honey-80);
      border-radius: 8px;
      border: 1px solid var(--accent-primary);
      display: grid;
      min-width: 67px;
      height: 68px;
      padding: 3px 8px 7px;
      span {
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        &:first-child {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }

  .option-more-button {
    color: var(--accent-primary);
    text-decoration: underline;
  }

  .original-price {
    margin: 0px 0px 5px;
    font-size: 20px;
    &.line-through {
      position: relative;
      text-decoration: none;
      &::after {
        content: '';
        position: absolute;
        width: 70%;
        height: 1.5px;
        background-color: var(--black);
        left: 10px;
        top: 50%;
        transform: rotate(-22deg);
      }
    }
    .line-text {
      border-color: var(--neutral-primary);
      height: 16px;
    }
    label {
      &.green-text {
        font-family: var(--cadiz);
        font-size: 16px;
        width: 140px !important;
        height: 24px !important;
        line-height: 24px;
      }
    }
  }
}

.facial-selected {
  border-color: var(--ocean-50);
  background-color: var(--ocean-20);
  &:hover {
    border-color: var(--ocean-75);
    background-color: var(--ocean-20);
    svg {
      rect {
        stroke: var(--ocean-75);
      }
    }
  }
}

.facial-container {
  .footer-continue {
    background: var(--neutral-secondary);
  }
}
