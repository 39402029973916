@import '../../../scss/mixin';
.success-screen-content {
  .appointment-link {
    padding-top: 10px;
  }
  padding: 24px 0px;
  .head-img {
    height: fit-content;
    object-fit: scale-down;
  }
  .success-screen-wrapper {
    padding-bottom: 24px;
  }
  .success-header-wrapper {
    display: flex;
    flex-direction: column;
    .success-screen-header {
      font-family: var(--tobias);
      font-size: 32px;
      line-height: 40px;
      float: left;
      color: var(--neutral-primary);
      padding-left: 10px;
      @media (min-width: 600px) {
        font-size: 40px;
        line-height: 48px;
      }
    }
    .first-member-message {
      font-size: 16px;
      color: var(--neutral-primary-light);
    }
  }
  .success-screen-header-icon {
    float: left;
    @media (min-width: 600px) {
      width: 48px;
      height: 48px;
    }
  }
  .success-screen-name {
    color: var(--neutral-primary);
    margin: 5px auto;
    @media (min-width: 600px) {
      font-size: 32px;
      line-height: 40px;
    }
  }
  .success-screen-date {
    font-family: var(--cadiz);
    font-size: 20px;
    line-height: 28px;
    color: var(--neutral-primary-light);
  }
  .success-screen-location {
    float: left;
    padding-left: 5px;
    color: var(--neutral-primary-light);
    margin-top: 5px;
  }
  .success-screen-location-icon {
    margin-top: 7px;
    float: left;
  }
  .ical-link {
    text-decoration: none;
  }
  .success-screen-btn {
    border-radius: 6px;
    padding: 12px 16px;
    text-transform: none;
    font-size: 16px;
    font-family: var(--cadiz);
    font-weight: 900;
    border: 1.5px solid var(--accent-primary) !important;
    color: var(--accent-primary);
    &.app-btn-outlined {
      background-color: transparent;
      border: 1.5px solid var(--accent-primary) !important;
      color: var(--accent-primary);
    }
  }

  .divider-wrapper {
    .divider {
      width: 100%;
      margin: 24px 0px;
    }
  }
  .success-screen-address1 {
    font-weight: 600 !important;
    color: var(--neutral-primary);
  }
  .success-screen-address2 {
    color: var(--neutral-primary-light);
  }
  .success-screen-icon-box {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: var(--accent-primary);
    padding-top: 8px;
    .success-screen-icon {
      margin-right: 10px;
    }
    .MuiLink-root {
      text-decoration-color: var(--accent-primary);
    }
  }
  .MuiGrid-item {
    padding-top: 0px !important;
  }
  .add-to-calender {
    margin-top: 24px;
    .body1 {
      margin: 0;
    }
  }
}
.content-body {
  padding-bottom: 0;
  .success-content {
    &::after {
      content: '';
      background: url('../../../assets/icons/bottumcurve.svg') no-repeat;
      width: 100%;
      background-size: 100%;
      margin-left: 16px;
      padding-top: 6.5% !important;
    }
    .success-curve {
      background: url('../../../assets/icons/curve.svg') no-repeat;
      width: 100%;
      background-size: 100%;
      margin-bottom: 40px;
      padding-bottom: 25px;
      margin-left: 15px;
      margin-top: -1px;
    }
    .success-content-wrapper {
      margin-left: 10px;
      padding: 0px 25px;
      .success-screen-content-head {
        font-family: var(--tobias);
        font-size: 32px;
        line-height: 40px;
        @include screen(mobile) {
          font-size: 24px;
          line-height: 32px;
        }
      }

      .stepper-wrapper {
        margin-top: 16px;
        .MuiStep-root {
          font-family: var(--cadiz);
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: var(--neutral-primary);
          padding-bottom: 16px;
          position: relative;
          &::after {
            content: '';
            display: block;
            position: absolute;
            height: 100%;
            background: var(--light-brown);
            width: 1px;
            left: 11px;
            top: 15px;
          }
          &:last-child::after {
            display: none;
          }
        }
        .MuiStepLabel-label.Mui-active {
          font-family: var(--cadiz);
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 28px;
          color: var(--neutral-primary);
          position: relative;
        }
        .MuiStepConnector-line {
          display: none;
        }
        .MuiStepContent-root {
          border-left: 0px;
          margin-left: 16px;
        }
        .MuiStepIcon-root.Mui-active {
          color: var(--light-brown);
          width: 16px;
          height: 16px;
          margin-left: 3px;
        }
        .MuiStepLabel-iconContainer {
          padding-right: 16px;
        }
        .MuiStepIcon-text {
          display: none;
        }
      }
      .stepper-label {
        font-family: var(--cadiz);
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
      }
      .success-reminder {
        margin-top: 40px;
        margin-bottom: 24px;
        .success-reminder-container {
          display: flex;
          justify-content: flex-start;
        }
        .success-reminder-head {
          font-family: var(--tobias);
          font-size: 32px;
          line-height: 40px;
          color: var(--neutral-primary);
          margin-bottom: 16px;
          font-style: normal;
          font-weight: normal;
          @include screen(mobile) {
            font-size: 24px;
            line-height: 32px;
          }
        }
        .success-reminder-content {
          margin-bottom: 16px;
          width: fit-content;
          color: var(--neutral-primary);
          padding: 0px 10px;
          padding-left: 40px;
          position: relative;
          &::before {
            content: '';
            display: block;
            position: absolute;
            top: 9px;
            left: 20px;
            width: 4px;
            height: 4px;
            background-color: var(--neutral-primary);
            border-radius: 100%;
          }
          a {
            text-decoration: none;
            position: relative;
            &::after {
              content: '';
              display: block;
              position: absolute;
              bottom: -2px;
              width: 100%;
              left: 0px;
              height: 1px;
              background-color: var(--accent-primary);
            }
          }
        }
      }
    }
  }
}

.footer-wrapper {
  .footer-content-wrapper {
    padding: 38px 24px 44px 24px !important;
    margin-left: 16px;
    margin-top: 20px;
    .footer-head {
      font-weight: 600 !important;
      left: 30px;
      bottom: 244px;
      padding-bottom: 8px;
      font-family: var(--cadiz) !important;
      line-height: 24px;
    }
    .footer-content-container {
      padding-bottom: 20px;
    }
    .footer-content {
      left: 30px;
      bottom: 176px;
      font-family: var(--cadiz);
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 20px;
    }
  }
}
