@import '../../../scss/mixin';

.filter {
  margin: 0px 24px 24px;
  display: flex;
  align-items: center;

  button {
    padding: 8px 12px;
    background-color: var(--accent-primary);
    text-transform: none;
    border-radius: 6px;
    font-family: var(--cadiz);
    font-weight: 600;
    font-size: 16px;
    flex-shrink: 0;

    &:hover {
      background-color: var(--accent-primary);
    }
  }
}

.filter-menu {
  .MuiPaper-root {
    @include screen('max-width: 550px') {
      left: 24px !important;
      width: calc(100% - 48px) !important;
    }
  }
}

.esthetician-item {
  .name-avatar {
    width: 48px !important;
    height: 48px !important;
    background-color: white !important;
    color: black !important;
    border: 1px solid var(--light-silver);
    font-family: var(--tobias) !important;
    margin-right: 12px;
    font-size: 24px !important;
  }
  .name-text {
    font-family: var(--cadiz);
    font-size: 20px;
    line-height: 28px;
    color: var(--neutral-primary);
  }
  .lastseen-text {
    font-family: var(--cadiz);
    font-size: 14px;
    line-height: 20px;
    color: var(--iron);
  }
  .check-icon {
    position: absolute;
    right: 20px;
  }
  .any-chip {
    height: 48px;
    border-radius: 24px;
    background: var(--oxygen);

    .MuiAvatar-root {
      height: 40px;
      width: 40px;
      background: var(--yellow-mist);
    }
    .MuiChip-label {
      font-family: var(--cadiz);
      font-size: 20px;
      line-height: 28px;
      padding-right: 20px;
    }
  }
}

.selected-pills {
  padding-left: 16px;
  flex-direction: row;
  overflow-x: auto;
  .MuiChip-root {
    height: 40px;
    border-radius: 20px;
    border: 1.5px solid var(--neutral-primary);
    margin-right: 10px;
    padding: 8px 12px;
    .close-icon {
      color: var(--neutral-primary);
      width: 16px;
      height: 16px;
      stroke-width: 1.5px;
    }
    .MuiChip-label {
      font-family: var(--cadiz);
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      padding-left: 0;
      padding-right: 8px;
    }
    .MuiChip-deleteIcon {
      margin: 0;
    }
  }
}
