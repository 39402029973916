.SnackbarContent-root {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 8px !important;
  font-family: var(--cadiz) !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  margin: auto;

  .SnackbarItem-message {
    width: 470px;
  }
}
.SnackbarItem-variantError {
  background-color: var(--negative-primary-darker) !important;
}
.SnackbarItem-variantWarning {
  background-color: var(--peony-50) !important;
  color: var(--neutral-primary) !important;
  font-family: var(--cadiz) !important;
  font-size: 16px !important;
  line-height: 24px !important;
}
.SnackbarItem-action {
  left: calc(100% - 70px);
  position: absolute;
}
.SnackbarItem-action button {
  color: var(--white);
}
.SnackbarItem-message {
  padding-right: 10% !important;
}
