@import '../../../scss/mixin';

.estheticianlist {
  padding: 20px 0px 20px 20px;
  border-bottom: 1px solid var(--neutral-secondary-dark);
  .estheticianlist-header {
    display: flex;
    align-items: center;
    .MuiAvatar-root {
      border: 1px solid var(--light-silver);
      background: var(--white);
      font-family: var(--tobias);
      font-size: 20px;
      color: var(--black);
      line-height: 28px;
    }
    .list-profile {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      font-family: var(--cadiz);
      .list-profile-name {
        color: var(--dark-blue);
        font-size: 16px;
      }
      .list-profile-status {
        color: var(--iron);
        font-size: 12px;
      }
    }
  }
  .estheticianlist-timeslots {
    display: flex;
    margin-top: 15px;
    overflow: auto;
    flex-wrap: wrap;
    @include screen(mobile) {
      flex-wrap: nowrap;
    }
    .timeslots-container {
      color: var(--accent-primary);
      font-size: 14px;
      font-weight: 600;
      border: 1px solid var(--accent-primary);
      padding: 7px;
      border-radius: 6px;
      margin-right: 10px;
      min-width: fit-content;
      margin-bottom: 10px;
      cursor: pointer;
      &:active {
        background: var(--neutral-secondary-dark);
      }
    }
    .no-slot {
      background-color: var(--neutral-secondary-dark);
      padding: 8px;
      border-radius: 4px;
    }
  }
  .any-chip {
    height: 48px;
    border-radius: 24px;
    background: var(--oxygen);

    .MuiAvatar-root {
      height: 40px;
      width: 40px;
      background: var(--yellow-mist);
    }
    .MuiChip-label {
      font-family: var(--cadiz);
      font-size: 18px;
      line-height: 28px;
      padding-right: 20px;
    }
  }
}
.new-booking-card-wrapper {
  background-color: var(--honey-50);
  display: flex;
  gap: 16px;
  position: relative;
  overflow: hidden;
  .txt {
    padding: 24px 0px 24px 32px;
    display: flex;
    flex-direction: column;
    .link-txt {
      font-weight: 600;
      color: var(--accent-primary);
    }
  }
  .card-image {
    svg {
      height: 100%;
    }
  }
  .bold {
    font-weight: 600 !important;
  }
  .new-icon {
    position: absolute;
    left: -6px;
    top: -8px;
  }
}
.limit-enhancement-dialog {
  .MuiDialog-paper {
    max-width: 415px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;

    h4 {
      margin: 0;
      font-size: 24px;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      margin-top: 12px;
      color: var(--neutral-primary-light);
      text-align: center;
    }

    .change-date {
      margin-bottom: 8px;
    }
    @include screen(mobile) {
      max-width: 320px;
      padding: 24px;
    }
  }
}
