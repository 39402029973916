.icon {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

* {
  box-sizing: border-box;
}

/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
  position: absolute;
  left: 0;
  top: 32%;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
  position: absolute;
  right: 0;
  top: 32%;
}

/* Calendar */

.calendar {
  display: block;
  position: absolute;
  width: 100%;
  background: var(--neutral-tertiary);
  z-index: 9;
  border: 1px solid var(--border-color);
  left: 0;
  right: 0;
  max-width: 550px;

  &.customPadding {
    padding: 0 40px;
  }

  .header {
    padding: 10px 0;
    justify-content: center;
    align-items: center;

    .icon {
      cursor: pointer;
      transition: 0.15s ease-out;

      &:hover {
        transition: 0.25s ease-out;
        color: var(--main-color);
      }

      &:first-of-type {
        margin-left: 26px;
        margin-top: 8px;
      }

      &:last-of-type {
        margin-right: 26px;
        margin-top: 8px;
      }
    }
  }
  .calendar-overlay {
    position: absolute;
    width: 100%;
    height: calc(100% - 90px);
    background: var(--white);
    opacity: 0.5;
    content: '';
    z-index: 1;
  }

  .days {
    text-transform: uppercase;
    font-weight: 400;
    color: var(--text-color-light);
    padding-top: 0.75em;
  }

  .body {
    .cell {
      position: relative;
      overflow: hidden;
      cursor: pointer;
      transition: 0.25s ease-out;
      font-size: 1.5em;
      background: var(--neutral-tertiary);
      padding: 5px;
      text-align: center;

      .number {
        font-size: 14px;
        line-height: 26px;
        font-weight: 600;
        color: var(--neutral-primary);
        border-radius: 100%;
        display: block;
        width: 26px;
        height: 26px;
        text-align: center;
        margin: auto;
      }

      .bg {
        font-weight: 700;
        line-height: 1;
        color: var(--main-color);
        opacity: 0;
        font-size: 8em;
        position: absolute;
        top: -0.2em;
        right: -0.05em;
        transition: 0.25s ease-out;
        letter-spacing: -0.07em;
      }

      &:hover {
        transition: 0.5s ease-out;

        .bg {
          opacity: 0.05;
          transition: 0.5s ease-in;
        }
      }

      &:last-child {
        border-right: none;
      }

      &.today .bg {
        color: var(--red);
        opacity: 0.05;
      }
    }

    .today {
      border-image-slice: 1;
    }

    .selected,
    .today {
      border-image-slice: 1;
      background-color: var(--blue) !important;
      border-radius: 100%;
      color: var(--white) !important;
    }

    .selected .bg {
      opacity: 0.05;
      transition: 0.5s ease-in;
    }

    .disabled {
      color: var(--neutral-primary-lighter);
      pointer-events: none;

      .number {
        color: var(--neutral-primary-lighter);
      }
    }

    .col {
      flex-grow: 0;
      flex-basis: calc(100% / 7);
      width: calc(100% / 7);
    }
  }
}

.days-name {
  font-family: var(--cadiz);
  color: var(--neutral-primary);
  font-size: 14px;
}
.selected-date {
  font-family: var(--cadiz);
  color: var(--neutral-primary);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.selected-month {
  font-family: var(--cadiz);
  color: var(--black);
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.calender-container {
  position: relative;
}
.curser-type {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.arrow-icon {
  margin-left: 10px;
}
.calender-container:before {
  content: '';
  height: calc(100% - 195px);
}

.col.col-start.header_icon,
.col.col-end.header_icon {
  top: 10px;
}
.calender-container .calendar {
  position: absolute;
  z-index: 9;
}
.calendar-custom-height {
  height: 112px;
}
.col.cell.inactiveDay {
  visibility: hidden;
}
@media only screen and (max-width: 550px) {
  .calendar {
    padding: 0px;

    .body,
    .days {
      padding-left: 24px;
      padding-right: 24px;
    }

    .header {
      .icon:last-of-type {
        margin-right: 1em;
      }
      .icon:first-of-type {
        margin-left: 1em;
      }
    }
  }
  .calendar.customPadding {
    padding: 0px;
  }
  .selected-date {
    font-size: 14px;
    line-height: 20px;
  }

  .calendar .body .col {
    flex-basis: calc(100% / 7);
    width: calc(100% / 7);
  }
}
