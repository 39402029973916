.app-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  background-color: var(--loader-backdrop);
  z-index: 9999;

  span {
    position: fixed;
    top: 250px;
  }
}
