.title-box1 {
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  max-width: 550px;
  z-index: 100;
  background-color: var(--white);

  .upper-main-head1 {
    padding: 16px 0;
    border-bottom: 1px solid rgba(53, 56, 61, 0.08);

    .cancel-icon1 {
      position: absolute;
      right: 30px;
      top: 16px;
    }

    .back-box {
      position: absolute;
      left: 20px;
      top: 12px;
      cursor: pointer;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      .back-icon {
        width: 16px;
        height: 15px;
      }
    }
  }

  // theme
  &.neutral-tertiary-background {
    background-color: var(--neutral-tertiary-dark);
  }
  &.light-orange-background {
    background-color: var(--light-orange);
  }
}
