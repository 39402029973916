@import '../../../scss/mixin';

.health-body-wrapper {
  padding-bottom: 95px !important;
}
.health-check-content {
  background: var(--neutral-tertiary-dark);
  padding-top: 55px;
  position: relative;
  margin-bottom: 95px;
  @include screen(mobile) {
    padding-top: 24px;
    padding-bottom: 30px;
  }
  &::after {
    position: absolute;
    width: 550px;
    height: 56px;
    content: '';
    right: 0;
    bottom: -52px;
    background-image: url(../../../../src/assets/pinafter-dark.png);
    background-repeat: no-repeat;
    background-position: right bottom;
  }
  .head-label {
    text-transform: uppercase;
    font-size: 14px;
  }
  p {
    margin-bottom: 0px;
  }

  h3 {
    margin-top: 0px;
    padding-top: 11px;
    margin-bottom: 20px;
    font-size: 40px;
    line-height: 48px;
    @include screen(mobile) {
      font-size: 28px;
      line-height: 36px;
    }
  }

  .facialCheckList {
    list-style: none;
    padding-left: 0px;
    margin-bottom: 24px;
    li {
      position: relative;
      padding-left: 20px;
      margin: 1px 0px;
      font-family: var(--cadiz);
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: var(--neutral-primary);
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 3px;
        height: 3px;
        background-color: var(--neutral-primary);
        border-radius: 100%;
        left: 4px;
        top: 13px;
        margin-top: -2px;
      }
    }
  }
}
