.appointment-container {
  padding: 24px;
  .heyday-banner {
    margin: 16px 0;
    width: 100%;
    height: auto;
  }
  .MuiSvgIcon-root {
    color: var(--neutral-primary-light) !important;
    margin-right: 8px;
    height: 16px;
    width: 16px;
  }
  .MuiIconButton-root {
    margin-right: 0px;
    padding: 0px;
  }
  .custom-divider {
    margin: 20px 0;
    border: 1px solid var(--neutral-secondary-dark);
    border-block-start-style: none;
  }
  .non-member-banner {
    margin: 20px 0px;
    padding: 16px;
    background-color: var(--neutral-secondary-dark);
    border-radius: 8px;
  }
}
