@import '../../../scss/_mixin.scss';

.general-head {
  .main-head {
    margin: 0;
  }

  .sub-head {
    font-family: var(--cadiz);
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: var(--neutral-primary-light);
  }

  .body-1 {
    display: flex;
    align-items: center;
    margin-top: 4px;

    .mappin-icon {
      margin-right: 4px;
    }
    .location-name {
      color: var(--neutral-primary-light);
    }
  }
}

.selected-enhancements {
  .enhancements-list {
    .selected-enhancement-label {
      font-family: var(--cadiz);
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: var(--neutral-primary-light);
    }

    .custom-alert {
      display: flex;
      align-items: center;
      margin: 12px 0;
      background: none;
      border: 1px solid var(--neutral-secondary-darker);
      border-radius: 8px;
      color: var(--neutral-primary);
      padding: 8px 12px;
      width: 100%;
      height: 40px;
      &:hover {
        background-color: var(--neutral-secondary-dark);
      }
      &:active {
        background-color: var(--neutral-secondary-darker);
      }
    }
  }
}
.delete-enhancement-dialog {
  .MuiDialog-paper {
    max-width: 415px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    gap: 32px;
    @include screen(mobile) {
      max-width: 320px;
      padding: 24px;
    }
    .delete-enhancement-dialog-text {
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 8px;
      h3 {
        margin: 0px;
      }
      .body-1 {
        color: var(--neutral-primary-light);
      }
    }
    .delete-enhancement-dialog-buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 16px;
      width: 100%;
      button {
        border-radius: 8px;
        padding: 16px;
        font-family: var(--cadiz);
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-transform: none;
      }
      .delete-enhancement-confirm-button {
        background-color: var(--negative-primary-dark);
        color: var(--neutral-secondary);
      }
      .delete-enhancement-cancel-button {
        color: var(--accent-primary);
        border: 2px solid var(--accent-primary);
      }
    }
  }
}

.price-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  .item-text {
    font-family: var(--cadiz);
    font-size: 16px;
    line-height: 24px;
  }
  .member-price {
    margin-left: 16px;
  }
  .strike-through {
    text-decoration-line: line-through;
    color: var(--neutral-primary-light);
  }
  .offer-text {
    font-family: var(--cadiz);
    font-size: 14px;
    line-height: 20px;
  }
  .success {
    color: var(--positive-primary-dark);
  }
}

.price-section {
  margin-top: 8px;
  margin-bottom: 24px;
  .member-text {
    padding: 16px;
    background-color: var(--neutral-secondary-dark);
    border-radius: 8px;
    font-style: italic;
  }
  .note {
    color: var(--neutral-primary-light);
    margin-top: 16px;
    font-style: italic;
  }
  .summer-promo-banner {
    background-color: var(--sky-50);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    padding: 16px;
    margin-top: 24px;
    .headline {
      font-weight: bold;
      font-size: 16px;
    }
    .description {
      font-size: 14px;
    }
  }
}
.total {
  border-top: 1px solid var(--neutral-primary-light);
  padding-top: 8px;
}

.offer-wrapper {
  .offer-banner {
    background-color: var(--neutral-tertiary);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 4px;
    position: static;
    margin: 0px;
    .offer-head {
      display: inline;
      align-items: center;
      .party-cap {
        margin-left: 8px;
      }
    }
  }
}

.fade-in {
  opacity: 1 !important;
}

.upsell-wrapper {
  background-color: var(--neutral-tertiary);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 8px;
  .upsell-head {
    display: flex;
    div {
      cursor: pointer;
    }
  }
  p {
    font-size: 14px;
    margin: 8px 0px;
  }
  h3 {
    margin: 0px;
  }
  .upsell-expanded {
    opacity: 0;
    transition: opacity 0.5s;
    font-family: var(--cadiz);
    display: flex;
    flex-direction: column;
    gap: 16px;
    h4 {
      font-family: var(--cadiz);
      font-size: 16px;
      line-height: 24px;
      margin: 0;
    }
    button {
      font-family: var(--sf-pro) !important;
    }
    .upsell-expanded-footer {
      font-size: 14px;
      font-style: italic;
      margin: 0px 0px 8px 0px;
      span {
        text-decoration: underline;
        cursor: pointer;
        color: var(--accent-primary);
      }
    }
    .upsell-benefit-list {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    .upsell-benefit-card {
      display: flex;
      column-gap: 10px;

      p:first-child {
        margin: 0;
        font-weight: 600;
        font-size: 16px;
        color: var(--accent-primary);
      }
      p:last-child {
        margin: 0;
        font-size: 16px;
        color: var(--neutral-primary-light);
      }
    }
  }
  .membership-agreement-popup {
    overflow-y: auto;
    background-color: var(--neutral-secondary);
    position: absolute;
    height: 100vh;
    z-index: 100;
    top: 0;
    left: 0;

    .membership-portable-text {
      margin-top: 13.5rem;
      @include screen(mobile) {
        margin-top: 15rem;
      }
    }
    .membership-agreement-wrapper {
      width: 100%;
      background: var(--sage-50);
      position: inherit;
      padding-top: 32px;
      @include screen(mobile) {
        padding-top: 0;
      }
      h2 {
        font-weight: 400;
        text-align: center;
        @include screen(mobile) {
          margin-top: 0;
          margin-bottom: 10px;
        }
        @include screen(tablet) {
          font-size: 32px;
        }
      }

      &::after {
        position: absolute;
        object-fit: cover;
        width: 550px;
        height: 28px;
        content: '';
        bottom: -25px;
        right: 0;
        background-image: url(../../../assets/sageafter.svg);
        background-repeat: no-repeat;
        background-position: right bottom;
      }

      .membership-agreement-close-icon {
        display: flex;
        justify-content: flex-end;
        svg {
          position: absolute;
          top: 16px;
          right: 16px;
          width: 48px;
          height: 48px;
          cursor: pointer;
          @include screen(mobile) {
            position: unset;
            width: 40px;
            height: 40px;
            margin: 12px;
          }
        }
      }
    }
  }

  .more-btn {
    margin-top: 8px;
  }
}

.membership-payment-section {
  .payment-head {
    gap: 12px !important;
  }
  .membership-card-selection {
    width: 100%;
    font-family: var(--cadiz);
    font-size: 16px;
    line-height: 24px;
    .MuiSelect-select {
      padding: 20px 23px 20px 16px !important;
      display: flex;
      align-items: center;
      border: 1px solid var(--neutral-secondary-dark);
      .card-num {
        margin-left: 16px;
        margin-right: 8px;
      }
      .exp-text {
        color: var(--negative-primary);
        margin-left: 8px;
      }
      .card-exp {
        margin-left: 8px;
        color: var(--neutral-primary-light);
      }

      .addcard-text {
        margin-left: 8px;
      }
      .trash-icon {
        display: none;
      }
    }
  }
  .add-newcard-form {
    margin-bottom: 24px;
    margin-top: 24px;
  }
}

.payment-section {
  .MuiAccordionSummary-content {
    margin: 0px !important;
  }
  .MuiAccordionSummary-root {
    padding: 0px;
    min-height: 0 !important;
  }
  .MuiAccordionSummary-root:hover {
    cursor: default !important;
  }
  .MuiAccordion-root {
    box-shadow: none;
  }
  .payment-head {
    display: flex;
    gap: 16px;
    font-family: var(--cadiz);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--neutral-primary);
    .MuiChip-root {
      background-color: var(--neutral-secondary-dark);
    }
    .MuiChip-label {
      font-family: var(--cadiz);
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--neutral-primary);
      border-radius: 50%;
    }
  }
  .MuiAccordionDetails-root {
    padding: 0px;
    padding-top: 16px;
  }

  .payment-text {
    color: var(--neutral-primary-light);
    margin: 0 0 16px;
  }
  .MuiList-root {
    padding: 0;
  }

  .card-selection {
    width: 100%;
    font-family: var(--cadiz);
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
    .MuiSelect-select {
      padding: 20px 23px 20px 16px !important;
      display: flex;
      align-items: center;
      border: 1px solid var(--neutral-secondary-dark);
      .card-num {
        margin-left: 16px;
        margin-right: 8px;
      }
      .exp-text {
        color: var(--negative-primary);
        margin-left: 8px;
      }
      .card-exp {
        margin-left: 8px;
        color: var(--neutral-primary-light);
      }

      .addcard-text {
        margin-left: 8px;
      }
      .trash-icon {
        display: none;
      }
    }
  }

  .add-newcard-form {
    margin-bottom: 24px;
  }
  .custom-divider {
    margin: 20px 0;
    border: 1px solid var(--neutral-secondary-dark);
    border-block-start-style: none;
  }
}

.promo-section {
  margin-bottom: 6px;
  .selected-promo {
    .MuiListItem-root {
      padding: 14px 0px;
    }

    .trash-icon {
      cursor: pointer;
    }
  }
  .MuiListItemSecondaryAction-root {
    right: 0;
  }
  .MuiListItemAvatar-root {
    margin-right: 16px;
    min-width: 0;
  }
  .add-promo-btn {
    display: flex;
    align-items: center;
    margin-top: 24px;
    cursor: pointer;

    .addpromo-text {
      margin-left: 16px;
    }
  }
  .apply-btn {
    color: var(--accent-primary);
    cursor: pointer;
    font-weight: 600;
    margin: 0 10px;
  }
  .error-text {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--negative-primary) !important;

    .err-icon {
      width: 16px;
      color: var(--negative-primary) !important;
    }
  }
  .promo-text {
    font-family: var(--cadiz);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: var(--neutral-primary-light);
    margin-top: 4px;
  }
}

.personal-note {
  margin: 24px 0px;
  padding-top: 5px;
  .optional-text {
    color: var(--neutral-primary-light);
  }
  .note-textarea {
    font-family: var(--cadiz);
    width: 100%;
    margin-top: 16px;
    padding: 16px;
    font-size: 16px;
    line-height: 24px;
    color: var(--neutral-primary);
    border-radius: 8px;
    border: 1px solid var(--neutral-secondary-darker);
    &:focus-visible {
      outline-color: var(--accent-primary);
    }
  }
}

.map-section {
  .map-img {
    width: 100%;
    height: auto;
    margin-bottom: 24px;
  }
}

.policy-text {
  margin-top: 8px;
  text-align: center;
}

.membership-card-items {
  .MuiPaper-root {
    border: 1px solid var(--neutral-secondary-darker);
    box-shadow:
      6px 8px 12px rgba(0, 0, 0, 0.04),
      1px 1px 4px rgba(0, 0, 0, 0.08),
      4px 6px 8px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    ul {
      padding: 0;
      li {
        padding: 20px 23px 20px 16px;
        border-bottom: 1px solid var(--neutral-secondary-dark);
        display: flex;
        justify-content: space-between;
        align-items: center;

        .card-num {
          margin-left: 16px;
          margin-right: 8px;
        }
        .exp-text {
          color: var(--negative-primary);
          margin-left: 8px;
        }
        .card-exp {
          margin-left: 8px;
          color: var(--neutral-primary-light);
        }

        .addcard-text {
          margin-left: 8px;
        }
      }
    }
  }
}

.card-items {
  .MuiPaper-root {
    border: 1px solid var(--neutral-secondary-darker);
    box-shadow:
      6px 8px 12px rgba(0, 0, 0, 0.04),
      1px 1px 4px rgba(0, 0, 0, 0.08),
      4px 6px 8px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    ul {
      padding: 0;
      li {
        padding: 20px 23px 20px 16px;
        border-bottom: 1px solid var(--neutral-secondary-dark);
        display: flex;
        justify-content: space-between;
        align-items: center;
        .card-num {
          margin-left: 16px;
          margin-right: 8px;
        }
        .exp-text {
          color: var(--negative-primary);
          margin-left: 8px;
        }
        .card-exp {
          margin-left: 8px;
          color: var(--neutral-primary-light);
        }

        .addcard-text {
          margin-left: 8px;
        }
      }
    }
  }
}

.membership-login-signup-box {
  .payment-head {
    gap: 12px !important;
  }
}

.login-signup-box {
  .MuiAccordionSummary-root {
    padding: 0px;
    min-height: 0;
    .Mui-expanded {
      transform: none;
      margin: 0px;
    }
  }
  .MuiAccordionSummary-root:hover {
    cursor: default !important;
  }
  .MuiAccordionSummary-content {
    margin: 0px;
  }
  .custom-margin {
    margin-top: 24px;
    .MuiGrid-item {
      padding-top: 0px;
    }
    .MuiFormControl-root {
      margin-top: 0px !important;
    }
  }
  .txt-link {
    font-family: var(--cadiz);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--accent-primary);
    text-transform: none;
  }
  .MuiAccordion-root {
    box-shadow: none;
  }
  .payment-head {
    display: flex;
    gap: 16px;
    font-family: var(--cadiz);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--neutral-primary);
    .MuiChip-root {
      background-color: var(--neutral-secondary-dark);
    }
    .MuiChip-label {
      font-family: var(--cadiz);
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--neutral-primary);
      border-radius: 50%;
    }
  }
  .signup-container {
    font-family: var(--cadiz);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .head-text {
    font-family: var(--tobias);
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 40px;
    color: var(--neutral-primary);
    padding: 20px;
  }
  .input-filed-wrapper {
    width: 100%;
    display: flex;
    .MuiTypography-root {
      color: var(--accent-primary);
      cursor: pointer;
    }
    .MuiFormControl-root {
      margin-top: 24px !important;
    }
  }
  .input-filed-wrapper-btn {
    width: 100%;
    display: flex;
    margin: 24px 0px;
    .MuiTypography-root {
      color: var(--accent-primary);
      cursor: pointer;
    }
  }
  .input-filed-wrapper-link {
    margin: 0;
    width: 100%;
    display: flex;
  }
  .number-txt-head {
    font-family: var(--cadiz);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--neutral-primary);
    padding-top: 12px;
  }
  .number-txt-body {
    font-family: var(--cadiz);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
    color: var(--neutral-primary);
  }
  .signup-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .txt-field {
    color: var(--neutral-primary);
    font-size: 16px;
    line-height: 24px;
    font-family: var(--cadiz);
    font-style: normal;
    font-weight: normal;
  }

  .MuiInput-input {
    padding: 0px;
  }
  .MuiButton-root {
    padding: 12px 16px;
  }
  .MuiAccordionDetails-root {
    padding: 0px;
  }
  .Mui-expanded {
    margin: 0px;
  }
  .MuiFormHelperText-root {
    font-family: var(--cadiz);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
    margin-left: 0px;

    &.Mui-error {
      margin: 8px 0px 0px;
      font-size: 14px;
    }
  }
  .signup-btn {
    border-radius: 6px;
    padding: 16px 40px;
    text-transform: none;
    font-size: 16px;
    font-family: var(--cadiz);
    font-weight: 900;
    color: var(--neutral-secondary) !important;
  }
  .link-txt {
    font-family: var(--cadiz);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-top: 12px;
    margin-bottom: 24px;
  }
  .accordion-summary {
    min-height: 0 !important;
  }
}
.SnackbarItem-variantSuccess {
  background-color: var(--positive-primary) !important;
  color: var(--neutral-primary) !important;
}
.SnackbarItem-action button {
  color: var(--neutral-primary) !important;
}
.membership-btn {
  margin-top: 24px !important;
}

.user-message {
  background-color: var(--oxygen);
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 22px;
  p,
  a {
    margin-top: 0px;
    line-height: 20px;
    font-weight: 400;
    color: var(--neutral-primary) !important;
    font-size: 14px;
    span {
      font-weight: 600;
    }
  }
  a {
    font-weight: 600;
  }
}
